<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">档案管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">一企一档</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">企业档案</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="企业名称" class="searchboxItem ci-full">
              <span class="itemLabel">企业名称:</span>
              <span>{{ $route.query.enterpriseName }}</span>
            </div>
            <div title="年份" class="searchboxItem ci-full">
              <span class="itemLabel">年份:</span>
              <el-date-picker
                v-model="value3"
                type="year"
                size="small"
                :picker-options="pickerOptions0"
                placeholder="选择年"
              >
              </el-date-picker>
            </div>
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="handldownload()"
              >下载全部档案</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="目录"
                align="left"
                prop="dictValue"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.dictValue }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="80px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handlEdit(scope.row, 'edit')"
                    >编辑</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <!-- code == 235 年度培训资金提取和使用情况管理台账 -->
    <el-dialog
      :title="new Date(value3.getTime()).Format('yy') + title"
      :visible.sync="dialogVisible"
      width="1200px"
      top="2%"
      @close="close"
    >
      <div style="display: flex; justify-content: flex-end">
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          size="small"
          @click="handleExport()"
          >批量导入</el-button
        >
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          size="small"
          @click="handlAdd('capitalAmount')"
          >新增</el-button
        >
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          size="small"
          @click="handlDelete()"
          >删除</el-button
        >
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          size="small"
          @click="handlDownloadcapitalAmount()"
          >下载</el-button
        >
      </div>
      <div class="dialogBox ovy-a">
        <div class="title">
          <h3>{{ new Date(value3.getTime()).Format("yy") + title }}</h3>
        </div>

        <div class="divBox2">
          <p>
            单位名称:<span>{{ Information.enterpriseName }}</span>
          </p>
          <table
            border="1"
            style="
              border-top: 1px solid #999;
              border-left: 1px solid #999;
              border-spacing: 0; /*去掉单元格间隙*/
            "
          >
            <tr>
              <td rowspan="3">日期</td>
              <td rowspan="3">提取金额</td>
              <td colspan="8">费用项目</td>
              <td rowspan="3">余额</td>
            </tr>
            <tr>
              <td rowspan="2">培训教材教具费</td>
              <td rowspan="2">师资费</td>
              <td rowspan="2">试卷印制费</td>
              <td rowspan="2">外出培训费</td>
              <td rowspan="2">教学设备、课桌椅等购置维护费</td>
              <td rowspan="2">培训活动费</td>
              <td rowspan="2">委托培训费</td>
              <td rowspan="2">其他与培训有关的直接支出</td>
            </tr>
            <tr></tr>
            <tr
              v-for="(item, index) in datas"
              :key="index"
              @click="removeClick(index, item.extractId)"
              @dblclick="dbClick(index, item)"
              :style="index == active.SelectExtract ? 'background:#d7d7d7' : ''"
              class="pointer"
              :rowspan="datas.length"
            >
              <td style="min-width: 8rem">
                <span>{{ item.extractDate }}</span>
              </td>
              <td style="min-width: 5rem">
                <span>{{ item.extractValue }}</span>
              </td>
              <td>
                <span>{{ item.extractTextBookFee }}</span>
              </td>
              <td>
                <span>{{ item.extractTeacherFee }}</span>
              </td>
              <td>
                <span>{{ item.extractPaperFee }}</span>
              </td>
              <td>
                <span>{{ item.extractGoOutFee }}</span>
              </td>
              <td>
                <span>{{ item.extractDeviceFee }}</span>
              </td>
              <td>
                <span>{{ item.extractTrainActivityFee }}</span>
              </td>
              <td>
                <span>{{ item.extractCommissionFee }}</span>
              </td>
              <td>
                <span>{{ item.extractOtherFee }}</span>
              </td>
              <td>
                <span>{{ item.extractBalance }}</span>
              </td>
            </tr>
          </table>

          <div class="tableFooter">
            <div>
              <div>
                <span
                  style="min-width: 3rem"
                  @click="extractTabulationNameClick(1)"
                  >制表人:</span
                >
                <el-input
                  v-if="preparedByShow"
                  size="small"
                  v-model="Information.preparedBy"
                  @blur="blurInput(1)"
                  placeholder="请输入制表人"
                />
                <p v-else @click="extractTabulationNameClick(1)">
                  {{ Information.preparedBy }} <i class="el-icon-edit"></i>
                </p>
              </div>
              <div>
                <span
                  style="min-width: 4rem"
                  @click="extractTabulationNameClick(2)"
                  >编制日期:</span
                >
                <el-date-picker
                  v-if="preparedDateShow"
                  @blur="blurInput(2)"
                  size="small"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  v-model="Information.preparedDate"
                  type="date"
                  placeholder="选择日期"
                />
                <p v-else @dblclick="extractTabulationNameClick(2)">
                  {{ Information.preparedDate }} <i class="el-icon-edit"></i>
                </p>
              </div>
            </div>
            <div>
              <div>
                <span
                  style="min-width: 3rem"
                  @click="extractTabulationNameClick(3)"
                  >审核人:</span
                >
                <el-input
                  v-if="auditByShow"
                  size="small"
                  v-model="Information.auditBy"
                  @blur="blurInput(2)"
                  placeholder="请输入审核人"
                />
                <p v-else @click="extractTabulationNameClick(3)">
                  {{ Information.auditBy }} <i class="el-icon-edit"></i>
                </p>
              </div>
              <div>
                <span
                  style="min-width: 4rem"
                  @click="extractTabulationNameClick(4)"
                  >审核日期:</span
                >
                <el-date-picker
                  @blur="blurInput(2)"
                  v-if="auditDateShow"
                  size="small"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  v-model="Information.auditDate"
                  type="date"
                  placeholder="选择日期"
                />
                <p v-else @click="extractTabulationNameClick(4)">
                  {{ Information.auditDate }} <i class="el-icon-edit"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
        <p style="margin-top: 15px">
          填表说明:1.将实际发生的费用金额记录在费用项目栏内。2.培训提取比例按职工工资1.5%，可按季或月提取。
        </p>
      </div>
    </el-dialog>
    <el-dialog
      :title="title1"
      :visible.sync="dialogVisible1"
      width="50%"
      top="5%"
      @close="close"
    >
      <div>
        <el-form ref="form" :model="form" label-width="220px">
          <el-form-item label="日期">
            <el-date-picker
              size="small"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              v-model="form.extractDate"
              type="date"
              :editable="false"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="提取金额">
            <el-input
              v-model="form.extractValue"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
          <el-form-item label="培训教材教具费">
            <el-input
              v-model="form.extractTextBookFee"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
          <el-form-item label="师资费">
            <el-input
              v-model="form.extractTeacherFee"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
          <el-form-item label="试卷印制费">
            <el-input
              v-model="form.extractPaperFee"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
          <el-form-item label="外出培训费">
            <el-input
              v-model="form.extractGoOutFee"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
          <el-form-item label="教学设备、课桌椅等购置维护费">
            <el-input
              v-model="form.extractDeviceFee"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
          <el-form-item label="培训活动费">
            <el-input
              v-model="form.extractTrainActivityFee"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
          <el-form-item label="委托培训费">
            <el-input
              v-model="form.extractCommissionFee"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
          <el-form-item label="其他与培训有关的直接支出">
            <el-input
              v-model="form.extractOtherFee"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
          <el-form-item label="余额">
            <el-input
              v-model="form.extractBalance"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button class="bgc-bv" @click="close">取消</el-button>
          <el-button class="bgc-bv" @click="doSave">保存</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :title="new Date(value3.getTime()).Format('yy') + title"
      :visible.sync="dialogVisibleExport"
      width="50%"
      center
      :before-close="doCloseload"
      class="exportdialog"
    >
      <div class="flexdcc">
        <div class="export-box">
          <h1>
            1
            <span>下载并填写导入模板</span>
          </h1>
          <div class="df">
            <span
              @click="downloadExcel"
              style="
                margin-top: 20px;
                margin-right: 20px;
                color: #409eff;
                cursor: pointer;
              "
              >年度培训资金提取和使用情况管理台账导入模板</span
            >
          </div>
        </div>
        <div class="export-box">
          <h1>
            2
            <span>导入编写好的Excel文档</span>
          </h1>
          <div class="flexdc">
            <div class="df" style="margin: 20px 0 10px">
              <el-upload
                class="upload-demo upload-workers"
                :action="actionUrl"
                :on-error="handleError"
                :on-success="handleSuccess"
                :on-change="uploadChange"
                :show-file-list="false"
                :auto-upload="false"
              >
                <el-button class="bgc-bv" style="font-size: 12px; width: 80%"
                  >浏览</el-button
                >
              </el-upload>
              <p v-if="fileName" style="margin-top: 10px; margin-left: 10px">
                当前选择文件：
                <span style="color: #f46173">{{ fileName }}</span>
              </p>
              <p v-else style="margin-top: 10px; margin-left: 10px">
                未选择文件
              </p>
            </div>
            <div>
              <el-button
                class="bgc-bv"
                size="mini"
                style="margin-top: 10px; height: 35px"
                @click="doExport"
                >开始导入</el-button
              >
            </div>
          </div>
        </div>
        <div class="export-box">
          <h1>
            3
            <span>导入结果</span>
          </h1>
          <div class="df studentNum">
            <span>总数量:{{ projectCount }}条;</span>
            <span>成功:{{ successCount }}条;</span>
            <span>失败:{{ errorCount }}条;</span>
          </div>
          <div>
            <el-button
              class="bgc-bv"
              style="margin-top: 15px; height: 35px"
              size="mini"
              :disabled="errorCount == '0'"
              @click="doExportError"
              >导出错误数据</el-button
            >
          </div>
          <div style="margin-top: 15px; color: #dd1d35" v-if="errorCount != 0">
            有导入失败数据，请导出错误数据，将导入失败数据调整后重新上传
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog width="50%" top="5%" @close="close">
      <div>
        <div class="btn">
          <el-button class="bgc-bv" @click="close">取消</el-button>
          <el-button class="bgc-bv" @click="doSave">保存</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- code == 225 年度安全培训教育计划-->
    <el-dialog
      :title="new Date(value3.getTime()).Format('yy') + title"
      :visible.sync="dialogTrainPlan"
      width="1200px"
      top="2%"
      @close="trainPlanclose"
    >
      <div style="display: flex; justify-content: flex-end">
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          size="small"
          @click="handlAdd('trainPlan')"
          >新增</el-button
        >
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          size="small"
          @click="handlDeleteTrainPlan()"
          >删除</el-button
        >
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          size="small"
          @click="handlDownloadTrainPlan()"
          >下载</el-button
        >
      </div>
      <div class="dialogBox ovy-a">
        <div class="title">
          <h3>{{ new Date(value3.getTime()).Format("yy") + title }}</h3>
        </div>

        <div class="divBox2">
          <p>
            单位名称:<span>{{ Information.enterpriseName }}</span>
          </p>
          <table
            border="1"
            style="
              border-top: 1px solid #999;
              border-left: 1px solid #999;
              border-spacing: 0; /*去掉单元格间隙*/
            "
          >
            <tr>
              <td>序号</td>
              <td>开始时间</td>
              <td>结束时间</td>
              <td>培训对象</td>
              <td>培训教材</td>
              <td>培训方式</td>
              <td>考核方式</td>
              <td>学时</td>
              <td>地点</td>
              <td>培训教师</td>
              <td>经费保障</td>
              <td>质量评估</td>
            </tr>
            <tr
              v-for="(item, index) in trainPlanList"
              :key="index"
              class="pointer"
              @click="removeClickTrainPlan(index, item.annualTrainId)"
              @dblclick="dbClicktrainPlan(index, item)"
              :style="
                index == active.SelectTrainPlan ? 'background:#d7d7d7' : ''
              "
            >
              <td>
                <span>{{ index + 1 }}</span>
              </td>
              <td>
                <span>{{ item.startTime }}</span>
              </td>
              <td>
                <span>{{ item.endTime }}</span>
              </td>
              <td>
                <span>{{ item.trainTarget }}</span>
              </td>
              <td>
                <span>{{ item.trainTextBook }}</span>
              </td>
              <td>
                <span>{{ item.trainWay }}</span>
              </td>
              <td>
                <span>{{ item.trainExamineWay }}</span>
              </td>
              <td>
                <span>{{ item.trainLesson }}</span>
              </td>
              <td>
                <span>{{ item.trainAddress }}</span>
              </td>
              <td>
                <span>{{ item.trainTeacher }}</span>
              </td>
              <td>
                <span>{{ item.trainFundingGuarantee }}</span>
              </td>
              <td>
                <span>{{ item.trainQualityAssessment }}</span>
              </td>
            </tr>
          </table>

          <div class="tableFooter3">
            <div>
              <div>
                <span
                  style="min-width: 3rem"
                  @click="extractTabulationNameClick(1)"
                  >编制人:</span
                >
                <el-input
                  v-if="preparedByShow"
                  size="small"
                  v-model="Information.preparedBy"
                  @blur="blurInput(3)"
                  clearable
                  placeholder="请输入编制人"
                ></el-input>
                <p v-else @click="extractTabulationNameClick(1)">
                  {{ Information.preparedBy }} <i class="el-icon-edit"></i>
                </p>
              </div>
              <div>
                <span
                  style="min-width: 4rem"
                  @click="extractTabulationNameClick(2)"
                  >编制日期:</span
                >
                <el-date-picker
                  @blur="blurInput(3)"
                  v-if="preparedDateShow"
                  size="small"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  v-model="Information.preparedDate"
                  type="date"
                  placeholder="选择编制日期"
                  clearable
                />
                <p v-else @click="extractTabulationNameClick(2)">
                  {{ Information.preparedDate }} <i class="el-icon-edit"></i>
                </p>
              </div>
            </div>
            <div>
              <div>
                <span
                  style="min-width: 3rem"
                  @click="extractTabulationNameClick(3)"
                  >审核人:</span
                >
                <el-input
                  v-if="auditByShow"
                  size="small"
                  v-model="Information.auditBy"
                  @blur="blurInput(3)"
                  placeholder="请输入审核人"
                />
                <p v-else @click="extractTabulationNameClick(3)">
                  {{ Information.auditBy }}
                </p>
              </div>
              <div>
                <span
                  style="min-width: 4rem"
                  @click="extractTabulationNameClick(4)"
                  >审核日期:</span
                >
                <el-date-picker
                  @blur="blurInput(3)"
                  v-if="auditDateShow"
                  size="small"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  v-model="Information.auditDate"
                  type="date"
                  placeholder="选择日期"
                />
                <p v-else @click="extractTabulationNameClick(4)">
                  {{ Information.auditDate }} <i class="el-icon-edit"></i>
                </p>
              </div>
            </div>
            <div>
              <div>
                <span
                  style="min-width: 3rem"
                  @click="extractTabulationNameClick(5)"
                  >批准人:</span
                >
                <el-input
                  v-if="approveByShow"
                  size="small"
                  v-model="Information.approveBy"
                  @blur="blurInput(3)"
                  placeholder="请输入批准人"
                />
                <p v-else @click="extractTabulationNameClick(5)">
                  {{ Information.approveBy }} <i class="el-icon-edit"></i>
                </p>
              </div>
              <div>
                <span
                  style="min-width: 4rem"
                  @click="extractTabulationNameClick(6)"
                  >批准日期:</span
                >
                <el-date-picker
                  @blur="blurInput(3)"
                  v-if="approveDateShow"
                  size="small"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  v-model="Information.approveDate"
                  type="date"
                  placeholder="选择日期"
                />
                <p v-else @click="extractTabulationNameClick(6)">
                  {{ Information.approveDate }} <i class="el-icon-edit"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :title="title2"
      :visible.sync="dialogTrainPlan2"
      width="50%"
      top="5%"
      @close="trainPlanclose"
    >
      <div>
        <el-form ref="form" :model="ruleForm" label-width="220px">
          <!-- <el-form-item label="时间">
            <el-input
              v-model="ruleForm.trainTime"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="培训时间：">
            <el-date-picker
              size="small"
              v-model="ruleForm.trainTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="培训对象">
            <el-input
              v-model="ruleForm.trainTarget"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
          <el-form-item label="培训教材">
            <el-input
              v-model="ruleForm.trainTextBook"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
          <el-form-item label="培训方式">
            <el-input
              v-model="ruleForm.trainWay"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
          <el-form-item label="考核方式">
            <el-input
              v-model="ruleForm.trainExamineWay"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
          <el-form-item label="学时">
            <el-input
              v-model="ruleForm.trainLesson"
              size="small"
              maxlength="11"
            ></el-input>
          </el-form-item>
          <el-form-item label="地点">
            <el-input
              v-model="ruleForm.trainAddress"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
          <el-form-item label="培训教师">
            <el-input
              v-model="ruleForm.trainTeacher"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
          <el-form-item label="经费保障">
            <el-input
              v-model="ruleForm.trainFundingGuarantee"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
          <el-form-item label="质量评估">
            <el-input
              v-model="ruleForm.trainQualityAssessment"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button class="bgc-bv" @click="trainPlanclose">取消</el-button>
          <el-button class="bgc-bv" @click="doSaveTrainPlan">保存</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- code == 210 年度三项岗位人员管理台账-->
    <el-dialog
      :title="new Date(value3.getTime()).Format('yy') + title"
      :visible.sync="dialogThreePost"
      width="1200px"
      top="2%"
      @close="ThreePostclose"
    >
      <div style="display: flex; justify-content: flex-end">
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          size="small"
          @click="handlAdd('threePost')"
          >新增</el-button
        >
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          size="small"
          @click="handlDeleteThreePost()"
          >删除</el-button
        >
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          size="small"
          @click="handlDownloadThreePost()"
          >下载</el-button
        >
      </div>
      <div class="dialogBox ovy-a">
        <div class="title">
          <h3>{{ new Date(value3.getTime()).Format("yy") + title }}</h3>
        </div>
        <div class="divBox2">
          <p>
            单位名称:<span>{{ Information.enterpriseName }}</span>
          </p>
          <table
            border="1"
            style="
              border-top: 1px solid #999;
              border-left: 1px solid #999;
              border-spacing: 0; /*去掉单元格间隙*/
            "
          >
            <tr>
              <td>序号</td>
              <td>姓名</td>
              <td>岗位/操作项目</td>
              <td>电话</td>
              <td>证书号</td>
              <td>证书有效期限</td>
              <td>复训日期</td>
            </tr>
            <tr
              v-for="(item, index) in threePostList"
              :key="index"
              class="pointer"
              @click="removeClickThreePost(index, item.threePostId)"
              @dblclick="dbClickthreePost(index, item)"
              :style="
                index == active.SelectThreePost ? 'background:#d7d7d7' : ''
              "
            >
              <td>
                <span>{{ index + 1 }}</span>
              </td>
              <td>
                <span>{{ item.threePostName }}</span>
              </td>
              <td>
                <span>{{ item.threePostPost }}</span>
              </td>
              <td>
                <span>{{ item.threePostPhone }}</span>
              </td>
              <td>
                <span>{{ item.threePostCertNo }}</span>
              </td>
              <td>
                <span>{{ item.threePostCertPeriod }}</span>
              </td>
              <td>
                <span>{{ item.threePostRetrainDate }}</span>
              </td>
            </tr>
          </table>

          <div class="tableFooter">
            <div>
              <div>
                <span
                  style="min-width: 7rem"
                  @click="extractTabulationNameClick(1)"
                  >档案管理人员:</span
                >
                <el-input
                  v-if="preparedByShow"
                  size="small"
                  v-model="Information.preparedBy"
                  @blur="blurInput(1)"
                  clearable
                  placeholder="请输入档案管理人员"
                />
                <p v-else @click="extractTabulationNameClick(1)">
                  {{ Information.preparedBy }} <i class="el-icon-edit"></i>
                </p>
              </div>
            </div>
            <div>
              <div>
                <span
                  style="min-width: 4rem"
                  @click="extractTabulationNameClick(2)"
                  >更新日期:</span
                >
                <el-date-picker
                  @blur="blurInput(1)"
                  v-if="preparedDateShow"
                  size="small"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  v-model="Information.preparedDate"
                  type="date"
                  placeholder="选择更新日期"
                  clearable
                />
                <p v-else @click="extractTabulationNameClick(2)">
                  {{ Information.preparedDate }} <i class="el-icon-edit"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :title="title2"
      :visible.sync="dialogThreePost2"
      width="50%"
      top="5%"
      @close="ThreePostclose"
    >
      <div>
        <el-form ref="form" :model="ruleForm1" label-width="220px">
          <el-form-item label="姓名">
            <el-input v-model="ruleForm1.threePostName" size="small"></el-input>
          </el-form-item>
          <el-form-item label="岗位/操作项目">
            <el-input v-model="ruleForm1.threePostPost" size="small"></el-input>
          </el-form-item>
          <el-form-item label="电话">
            <el-input
              v-model="ruleForm1.threePostPhone"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="证书号">
            <el-input
              v-model="ruleForm1.threePostCertNo"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="证书有效期限">
            <el-input
              v-model="ruleForm1.threePostCertPeriod"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="复训日期">
            <el-date-picker
              size="small"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              v-model="ruleForm1.threePostRetrainDate"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button class="bgc-bv" @click="ThreePostclose">取消</el-button>
          <el-button class="bgc-bv" @click="doSaveThreePost">保存</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- code == 220 年度安全培训师资管理台账-->
    <el-dialog
      :title="new Date(value3.getTime()).Format('yy') + '年度' + title"
      :visible.sync="dialogTeacherAccount"
      width="1200px"
      top="2%"
      @close="TeacherAccountclose"
    >
      <div style="display: flex; justify-content: flex-end">
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          size="small"
          @click="handlUpdateTeacherAccount()"
          >更新数据</el-button
        >
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          size="small"
          @click="handlAdd('teacherAccount')"
          >新增</el-button
        >
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          size="small"
          @click="handlDeleteTeacherAccount()"
          >删除</el-button
        >
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          size="small"
          @click="handlDownloadTeacherAccount()"
          >下载</el-button
        >
      </div>
      <div class="dialogBox ovy-a">
        <div class="title">
          <h3>
            {{ new Date(value3.getTime()).Format("yy") + "年度" + title }}
          </h3>
        </div>

        <div class="divBox2">
          <p>
            单位名称:<span>{{ Information.enterpriseName }}</span>
          </p>
          <table
            border="1"
            style="
              border-top: 1px solid #999;
              border-left: 1px solid #999;
              border-spacing: 0; /*去掉单元格间隙*/
            "
          >
            <tr>
              <td>序号</td>
              <td>姓名</td>
              <td>电话</td>
              <td>从事本专业工作年限</td>
              <td>专/兼职</td>
              <td>证书编号</td>
              <td>考核部门</td>
              <td>考核日期</td>
              <td>考核结果</td>
              <td>备注</td>
            </tr>
            <tr
              v-for="(item, index) in teacherAccountList"
              :key="index"
              @click="
                removeClickteacherAccount(
                  index,
                  item.teacherId,
                  item.allowDelete
                )
              "
              @dblclick="dbClickteacherAccount(index, item)"
              :style="
                index == active.SelectTeacherAccount ? 'background:#d7d7d7' : ''
              "
              class="pointer"
            >
              <td>
                <span>{{ index + 1 }}</span>
              </td>
              <td>
                <span>{{ item.teacherName }}</span>
              </td>
              <td>
                <span>{{ item.teacherPhoneNumber }}</span>
              </td>
              <td>
                <span>{{ item.workLonger }}</span>
              </td>
              <td>
                <span>{{ item.isFullTime }}</span>
              </td>
              <td>
                <span>{{ item.certificateNo }}</span>
              </td>
              <td>
                <span>{{ item.assessDep }}</span>
              </td>
              <td>
                <span>{{ item.assessDate }}</span>
              </td>
              <td>
                <span>{{ item.assessResult }}</span>
              </td>
              <td>
                <span>{{ item.remarks }}</span>
              </td>
            </tr>
          </table>

          <div class="tableFooter">
            <div>
              <div>
                <span
                  style="min-width: 4rem"
                  @click="extractTabulationNameClick(1)"
                  >管理人员:</span
                >
                <el-input
                  v-if="preparedByShow"
                  size="small"
                  v-model="Information.preparedBy"
                  @blur="blurInput(1)"
                  clearable
                  placeholder="请输入管理人员"
                />
                <p v-else @click="extractTabulationNameClick(1)">
                  {{ Information.preparedBy }} <i class="el-icon-edit"></i>
                </p>
              </div>
            </div>
            <div>
              <div>
                <span
                  style="min-width: 4rem"
                  @click="extractTabulationNameClick(2)"
                  >更新日期:</span
                >
                <el-date-picker
                  @blur="blurInput(1)"
                  v-if="preparedDateShow"
                  size="small"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  v-model="Information.preparedDate"
                  type="date"
                  placeholder="选择更新日期"
                  clearable
                />
                <p v-else @click="extractTabulationNameClick(2)">
                  {{ Information.preparedDate }} <i class="el-icon-edit"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :title="title2"
      :visible.sync="dialogTeacherAccount2"
      width="50%"
      top="5%"
      @close="TeacherAccountclose"
    >
      <div>
        <el-form ref="form" :model="ruleForm3" label-width="220px">
          <el-form-item label="姓名">
            <el-input
              v-model="ruleForm3.teacherName"
              size="small"
              maxlength="255"
              :readonly="!ruleForm3.allowDelete"
            ></el-input>
          </el-form-item>
          <el-form-item label="电话">
            <el-input
              v-model="ruleForm3.teacherPhoneNumber"
              size="small"
              maxlength="255"
              :readonly="!ruleForm3.allowDelete"
            ></el-input>
          </el-form-item>
          <el-form-item label="从事本专业工作年限">
            <el-input
              v-model="ruleForm3.workLonger"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
          <el-form-item label="专/兼职">
            <el-input
              v-model="ruleForm3.isFullTime"
              :readonly="!ruleForm3.allowDelete"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
          <el-form-item label="证书编号">
            <el-input
              v-model="ruleForm3.certificateNo"
              :readonly="!ruleForm3.allowDelete"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
          <el-form-item label="考核部门">
            <el-input
              v-model="ruleForm3.assessDep"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>

          <el-form-item label="考核日期">
            <el-date-picker
              size="small"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              v-model="ruleForm3.assessDate"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="考核结果">
            <el-input
              v-model="ruleForm3.assessResult"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              v-model="ruleForm3.remarks"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button class="bgc-bv" @click="TeacherAccountclose"
            >取消</el-button
          >
          <el-button class="bgc-bv" @click="doSaveTeacherAccount"
            >保存</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!-- code == 230 -->
    <el-dialog
      :title="new Date(value3.getTime()).Format('yy') + title"
      :visible.sync="dialogTrainingEducation"
      width="1200px"
      top="2%"
      @close="TrainingEducationClose"
    >
      <div style="display: flex; justify-content: flex-end">
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          size="small"
          @click="handlrefreshData('TrainingEducation')"
          >更新数据</el-button
        >
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          size="small"
          @click="handlAdd('TrainingEducation')"
          >新增</el-button
        >
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          size="small"
          @click="handlDeleteTraingEducation()"
          >删除</el-button
        >
        <el-button
          class="bgc-bv"
          style="margin: 0 10px"
          size="small"
          @click="handlDownloadTrainingEducation()"
          >下载</el-button
        >
      </div>
      <div class="dialogBox ovy-a">
        <div class="title">
          <h3>{{ new Date(this.value3.getTime()).Format("yy") + title }}</h3>
        </div>
        <p>
          单位名称:<span>{{ Information.enterpriseName }}</span>
        </p>
        <div class="divBox2">
          <table
            border="1"
            style="
              border-top: 1px solid #999;
              border-left: 1px solid #999;
              border-spacing: 0; /*去掉单元格间隙*/
            "
          >
            <tr>
              <td>序号</td>
              <td>培训时间</td>
              <td>培训对象</td>
              <td>培训类型</td>
              <td>培训内容</td>
              <td>参加人数</td>
              <td>培训学时</td>
              <td>培训地点</td>
              <td>培训教师</td>
              <td>考核方式</td>
              <td>汇总考核情况</td>
            </tr>
            <tr
              v-for="(item, index) in traingEducationList"
              :key="index"
              @click="removeClickTrainingEducation(index, item.ledgerId)"
              @dblclick="dbClickTrainingEducation(index, item)"
              :style="
                index == active.SelectTrainingEducation
                  ? 'background:#d7d7d7'
                  : ''
              "
              class="pointer"
            >
              <td>
                <span>{{ index + 1 }}</span>
              </td>
              <td>
                <span>{{ item.trainDate | momentDate }}</span>
              </td>
              <td>
                <span>{{ item.trainTarget }}</span>
              </td>
              <td>
                <span>{{ item.trainType }}</span>
              </td>
              <td>
                <span>{{ item.trainContent }}</span>
              </td>
              <td>
                <span>{{ item.trainPeopleCount }}</span>
              </td>
              <td>
                <span>{{ item.trainLesson }}</span>
              </td>
              <td>
                <span>{{ item.trainLocation }}</span>
              </td>
              <td>
                <span>{{ item.trainTeacher }}</span>
              </td>
              <td>
                <span>{{ item.trainAssessWay }}</span>
              </td>
              <td>
                <span>{{ item.trainAssessInfo }}</span>
              </td>
            </tr>
          </table>

          <div class="tableFooter">
            <div>
              <div>
                <span
                  style="min-width: 4rem"
                  @click="extractTabulationNameClick(1)"
                  >填表人:</span
                >
                <el-input
                  v-if="preparedByShow"
                  size="small"
                  clearable
                  v-model="Information.preparedBy"
                  @blur="blurInput(1)"
                  placeholder="请输入填表人"
                />
                <p v-else @click="extractTabulationNameClick(1)">
                  {{ Information.preparedBy }} <i class="el-icon-edit"></i>
                </p>
              </div>
            </div>
            <div>
              <div>
                <span
                  style="min-width: 4rem"
                  @click="extractTabulationNameClick(2)"
                  >填表日期:</span
                >
                <el-date-picker
                  clearable
                  @blur="blurInput(1)"
                  v-if="preparedDateShow"
                  size="small"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  v-model="Information.preparedDate"
                  type="date"
                  placeholder="选择更新日期"
                />
                <p v-else @click="extractTabulationNameClick(2)">
                  {{ Information.preparedDate }} <i class="el-icon-edit"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :title="title2"
      :visible.sync="dialogTrainingEducation2"
      width="50%"
      top="5%"
      @close="TrainingEducationClose"
    >
      <div>
        <el-form ref="form" :model="ruleForm2" label-width="220px">
          <el-form-item label="培训时间">
            <el-date-picker
              size="small"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              v-model="ruleForm2.trainDate"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="培训对象">
            <el-select
              v-model="ruleForm2.trainTarget"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in Trainees"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="培训类型">
            <el-input
              v-model="ruleForm2.trainType"
              size="small"
              maxlength="32"
            ></el-input>
          </el-form-item>
          <el-form-item label="培训内容">
            <el-input
              v-model="ruleForm2.trainContent"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
          <el-form-item label="参加人数">
            <el-input
              v-model="ruleForm2.trainPeopleCount"
              size="small"
              maxlength="6"
            ></el-input>
          </el-form-item>
          <el-form-item label="培训学时">
            <el-input
              v-model="ruleForm2.trainLesson"
              size="small"
              maxlength="6"
            ></el-input>
          </el-form-item>
          <el-form-item label="培训地点">
            <el-input
              :readonly="true"
              v-model="ruleForm2.trainLocation"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
          <el-form-item label="培训教师">
            <el-input
              v-model="ruleForm2.trainTeacher"
              size="small"
              maxlength="32"
            ></el-input>
          </el-form-item>
          <el-form-item label="考核方式">
            <el-input
              :readonly="true"
              v-model="ruleForm2.trainAssessWay"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
          <el-form-item label="汇总考核情况">
            <el-input
              v-model="ruleForm2.trainAssessInfo"
              size="small"
              maxlength="255"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button class="bgc-bv" @click="TrainingEducationClose"
            >取消</el-button
          >
          <el-button class="bgc-bv" @click="doSaveTrainingEducation"
            >保存</el-button
          >
        </div>
      </div>
    </el-dialog>
    <editPopup ref="editPopup" :editPopupData="editPopupData" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import pdf from "pdfobject";
import editPopup from "@/components/editPopup.vue";
import { mapGetters } from "vuex";
export default {
  name: "OneEnterpriseArchives",
  components: {
    Empty,
    editPopup,
  },
  mixins: [List],
  data() {
    return {
      active: {
        SelectExtract: -1,
        SelectTrainPlan: -1,
        SelectThreePost: -1,
        SelectTeacherAccount: -1,
        SelectTrainingEducation: -1,
      },
      pickerOptions0: {
        disabledDate(time) {
          var myDate = new Date();
          var tYear = myDate.getFullYear();
          return time.getFullYear() > tYear;
        },
      },
      title: "",
      title2: "",
      value3: "",
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisibleExport: false,
      dialogTrainPlan: false,
      dialogTrainPlan2: false,
      dialogThreePost: false,
      dialogThreePost2: false,
      dialogTeacherAccount: false, // 220
      dialogTeacherAccount2: false, // 220
      dialogTrainingEducation: false, // 230
      dialogTrainingEducation2: false, // 230
      // editPopupDialogVisible: false, // 模板 - 一企一档 - 编辑时的弹出框
      tableData: [],
      datas: [],
      form: {},
      ruleForm: {}, //年度安全培训教育计划对象
      ruleForm1: {}, //三岗人员管理台账
      ruleForm2: {
        trainLocation: "安知职业培训平台",
        trainAssessWay: "试卷考试",
      }, //安全培训教育管理台账
      ruleForm3: {},
      Trainees: [
        {
          value: "安全管理人员",
          label: "安全管理人员",
        },
        {
          value: "从业人员",
          label: "从业人员",
        },
        {
          value: "维修工",
          label: "维修工",
        },
        {
          value: "承包商",
          label: "承包商",
        },
        {
          value: "特种作业人员",
          label: "特种作业人员",
        },
      ],
      Information: {},
      extractId: "",
      preparedByShow: false, //制表人
      preparedDateShow: false, //制表人日期
      approveByShow: false, //批准人
      approveDateShow: false, //批准日期
      auditByShow: false, //审核人
      auditDateShow: false, //审核人日期
      trainPlanList: [], // 年度安全培训教育计划
      threePostList: [], // 三岗人员管理台账列表数据
      traingEducationList: [], // 安全培训教育管理台账
      editPopupData: {},
      teacherAccountList: [], // 本单位师资管理台账220
      fileName: "",
      fileKey: "",
      projectCount: 0,
      successCount: 0,
      errorCount: 0,
    };
  },
  created() {
    var date = new Date();
    this.value3 = new Date(String(date.getFullYear()));
    this.getData2();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    getData2() {
      this.$post("/dm/enterprise/record/category/list")
        .then((ret) => {
          this.tableData = ret.data;
        })
        .catch((err) => {
          return;
        });
    },
    //失去焦点
    blurInput(stu) {
      let attribute = {};
      this.preparedByShow = false; //制表人
      this.preparedDateShow = false; //制表人日期
      this.approveByShow = false; //批准人
      this.approveDateShow = false; //批准日期
      this.auditByShow = false; //审核人
      this.auditDateShow = false; //审核人日期
      if (stu == 3) {
        //带批准人
        attribute = {
          approveBy: this.Information.approveBy || "",
          approveDate: this.Information.approveDate || "",
          auditBy: this.Information.auditBy || "",
          auditDate: this.Information.auditDate || "",
          preparedBy: this.Information.preparedBy || "",
          preparedDate: this.Information.preparedDate || "",
        };
      } else if (stu == 2) {
        //带审核人
        attribute = {
          auditBy: this.Information.auditBy || "",
          auditDate: this.Information.auditDate || "",
          preparedBy: this.Information.preparedBy || "",
          preparedDate: this.Information.preparedDate || "",
        };
      } else if (stu == 1) {
        //只有制表人
        attribute = {
          preparedBy: this.Information.preparedBy || "",
          preparedDate: this.Information.preparedDate || "",
        };
      }

      let parmar = {
        year: new Date(this.value3.getTime()).Format("yy"),
        enterpriseId: this.$route.query.enterpriseId,
        docType: this.dictCode,
        attribute: JSON.stringify(attribute),
      };
      if (this.Information.documentInfoId) {
        parmar.documentInfoId = this.Information.documentInfoId;
      }
      this.$post(
        this.Information.documentInfoId
          ? "/biz/document/info/modifyEnterprise"
          : "/biz/document/info/insertEnterprise",
        parmar
      )
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            if (this.dictCode == "235") {
              this.getqueryList();
            }
            if (this.dictCode == "225") {
              this.getTrainPlanList();
            }
            if (this.dictCode == "210") {
              this.getThreePostList();
            }
          }
        })
        .catch((err) => {
          console.log(err.data);
          if ((err.data.status = "-1")) {
            this.Information.auditDate = "";
          }
        });
    },
    //编辑
    handlEdit(row) {
      this.title = row.dictValue;
      this.dictCode = row.dictCode;
      if (row.dictCode == "235") {
        this.dialogVisible = true;
        this.getqueryList();
      }
      if (row.dictCode == "225") {
        this.dialogTrainPlan = true;
        this.getTrainPlanList();
      }
      if (row.dictCode == "210") {
        this.dialogThreePost = true;
        this.getThreePostList();
      }
      if (row.dictCode == "220") {
        this.dialogTeacherAccount = true;
        this.getTeacherAccountList();
      }
      if (row.dictCode == "230") {
        this.dialogTrainingEducation = true;
        this.getTraingEducationList();
      }
      if (
        row.dictCode == "200" ||
        row.dictCode == "205" ||
        row.dictCode == "215" ||
        row.dictCode == "240"
      ) {
        this.editPopupData = {
          compId: this.$route.query.compId,
          enterpriseId: this.$route.query.enterpriseId,
          docType: row.dictCode,
          year: new Date(this.value3.getTime()).Format("yy"),
          btnShow: true,
          title: row.dictValue,
        };
        this.$refs.editPopup.showPopUp(this.editPopupData);
      }
    },
    // 下载全部档案
    handldownload() {
      let parmar = {
        compId: this.$route.query.compId,
        enterpriseId: this.$route.query.enterpriseId,
        // docType: row.dictCode,
        year: new Date(this.value3.getTime()).Format("yy"),
      };
      this.$post("/dm/enterprise/record/enterprise/download", parmar)
        .then((res) => {
          if (res.status == "0") {
            let list = res.data;
            console.log(list);
            if (!this.downloadItems.includes(list.taskId)) {
              this.$store.dispatch("pushDownloadItems", list.taskId);
            } else {
              this.$message.warning(
                "[" + list.fileName + "]已经申请下载,请耐心等待"
              );
            }
            this.closeDocsDialog();
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          return;
        });
    },
    /* 弹框新增 */
    handlAdd(stu) {
      if (stu == "capitalAmount") {
        this.datas.unshift({
          classStyle: false,
          extractDate: "",
          extractValue: "",
          extractTextBookFee: "",
          extractTeacherFee: "",
          extractPaperFee: "",
          extractGoOutFee: "",
          extractDeviceFee: "",
          extractTrainActivityFee: "",
          extractCommissionFee: "",
          extractOtherFee: "",
        });
      } else if (stu == "trainPlan") {
        this.trainPlanList.unshift({
          classStyle: false,
          trainTime: "",
          trainTarget: "",
          trainTextBook: "",
          trainWay: "",
          trainExamineWay: "",
          trainLesson: "",
          trainFundingGuarantee: "",
          trainTeacher: "",
          trainFundingGuarantee: "",
          trainQualityAssessment: "",
        });
      } else if (stu == "threePost") {
        this.threePostList.unshift({
          classStyle: false,
          threePostName: "",
          threePostPost: "",
          threePostPhone: "",
          threePostCertNo: "",
          threePostCertPeriod: "",
          threePostRetrainDate: "",
          threePostRemark: "",
        });
      } else if (stu == "TrainingEducation") {
        this.traingEducationList.unshift({
          classStyle: false,
          trainDate: "",
          trainTarget: "",
          trainType: "",
          trainContent: "",
          trainPeopleCount: "",
          trainLesson: "",
          trainLocation: "",
          trainTeacher: "",
          trainAssessWay: "",
          trainAssessInfo: "",
        });
      } else if (stu == "teacherAccount") {
        this.teacherAccountList.unshift({
          classStyle: false,
          allowDelete: true,
          teacherName: "",
          teacherPhoneNumber: "",
          workLonger: "",
          isFullTime: "",
          certificateNo: "",
          assessDep: "",
          assessDate: "",
          assessResult: "",
          remarks: "",
        });
      }
    },
    //年度培训资金提取和使用情况管理台账235
    doSave() {
      delete this.form.createTime;
      let parmar = {
        ...this.form,
        year: new Date(this.value3.getTime()).Format("yy"),
        enterpriseId: this.$route.query.enterpriseId,
        docType: this.dictCode,
      };
      this.$post(
        this.form.extractId
          ? "/biz/document/capital-amount/modify"
          : "/biz/document/capital-amount/insert",
        parmar
      ).then((ret) => {
        if (ret.status == 0) {
          this.$message({
            message: ret.message,
            type: "success",
          });
          this.dialogVisible1 = false;
          this.form = {};
          this.getqueryList();
          this.index = undefined;
          this.active.SelectExtract = -1;
        }
      });
    },
    handlDelete() {
      console.log(this.index);
      if (this.extractId) {
        this.$post("/biz/document/capital-amount/delete", {
          extractId: this.extractId,
        })
          .then((ret) => {
            if (ret.status == 0) {
              this.$message({
                message: ret.message,
                type: "success",
              });
              this.getqueryList();
              this.active.SelectExtract = -1;
              this.index = undefined;
              this.extractId = "";
            }
          })
          .catch((err) => {
            return;
          });
      } else {
        if (this.index == undefined) {
          this.$message({
            message: "请选择一行进行删除",
            type: "warning",
          });
        } else {
          this.datas.splice(this.index, 1);
        }
      }
    },
    getqueryList() {
      let parmar = {
        enterpriseId: this.$route.query.enterpriseId,
        year: new Date(this.value3.getTime()).Format("yy"),
      };
      this.$post("/biz/document/capital-amount/queryList", parmar)
        .then((ret) => {
          this.datas = ret.data.amountList || [];
          this.Information = ret.data || {};
          this.datas.map((el) => {
            el.classStyle = false;
          });
          this.auditDateShow = false;
        })
        .catch((err) => {
          return;
        });
    },
    removeClick(index, extractId) {
      this.extractId = extractId;
      this.index = index;
      this.active.SelectExtract = index;
    },
    dbClick(index, item) {
      this.dialogVisible1 = true;
      this.title1 = "新增";
      if (item.extractId) {
        this.title1 = "修改";
        this.form = {
          ...item,
        };
      }
    },
    handlDownloadcapitalAmount() {
      let parmar = {
        year: new Date(this.value3.getTime()).Format("yy"),
        enterpriseId: this.$route.query.enterpriseId,
        docType: this.dictCode,
      };
      this.$post("/biz/document/capital-amount/downloadCapitalAmount", parmar)
        .then((ret) => {
          if (ret.status == "0") {
            window.open(ret.data.downloadFileUrl);
          } else {
            this.$message.warning({ message: res.message, duration: 1500 });
          }
        })
        .catch((err) => {
          return;
        });
    },
    close() {
      this.form = {};
      (this.dialogVisible1 = false), (this.active.SelectExtract = -1);
    },
    //年度安全培训教育计划225
    getTrainPlanList() {
      let parmar = {
        enterpriseId: this.$route.query.enterpriseId,
        year: new Date(this.value3.getTime()).Format("yy"),
        docType: this.dictCode,
      };
      this.$post("/biz/document/annual-train-plan/queryList", parmar)
        .then((ret) => {
          this.trainPlanList = ret.data.trainPlanList || [];
          this.Information = ret.data || {};
          this.trainPlanList.map((el) => {
            el.classStyle = false;
          });
        })
        .catch((err) => {
          return;
        });
    },
    dbClicktrainPlan(index, item) {
      this.dialogTrainPlan2 = true;
      this.title2 = "新增";
      if (item.annualTrainId) {
        this.title2 = "修改";
        this.ruleForm = {
          ...item,
          trainTime: [
            item.startTime.replaceAll("/", "-"),
            item.endTime.replaceAll("/", "-"),
          ],
        };
      }
    },
    doSaveTrainPlan() {
      delete this.ruleForm.createTime;
      let parmar = {
        // ...this.ruleForm,

        trainTarget: this.ruleForm.trainTarget,
        trainTextBook: this.ruleForm.trainTextBook,
        trainWay: this.ruleForm.trainWay,
        trainExamineWay: this.ruleForm.trainExamineWay,
        trainLesson: this.ruleForm.trainExamineWay,
        trainAddress: this.ruleForm.trainAddress,
        trainTeacher: this.ruleForm.trainTeacher,
        trainFundingGuarantee: this.ruleForm.trainFundingGuarantee,
        trainQualityAssessment: this.ruleForm.trainQualityAssessment,
        startTime: this.ruleForm.trainTime[0],
        endTime: this.ruleForm.trainTime[1],
        year: new Date(this.value3.getTime()).Format("yy"),
        enterpriseId: this.$route.query.enterpriseId,
        docType: this.dictCode,
      };
      if (this.ruleForm.annualTrainId) {
        parmar.annualTrainId = this.ruleForm.annualTrainId;
      }
      this.$post(
        this.ruleForm.annualTrainId
          ? "/biz/document/annual-train-plan/modify"
          : "/biz/document/annual-train-plan/insert",
        parmar
      ).then((ret) => {
        if (ret.status == 0) {
          this.$message({
            message: ret.message,
            type: "success",
          });
          this.dialogTrainPlan2 = false;
          this.ruleForm = {};
          this.getTrainPlanList();
          this.index = undefined;
          this.active.SelectTrainPlan = -1;
        }
      });
    },
    removeClickTrainPlan(index, annualTrainId) {
      this.annualTrainId = annualTrainId;
      this.index = index;
      this.active.SelectTrainPlan = index;
    },
    handlDeleteTrainPlan() {
      if (this.annualTrainId) {
        this.$post("/biz/document/annual-train-plan/delete", {
          annualTrainId: this.annualTrainId,
        })
          .then((ret) => {
            if (ret.status == 0) {
              this.$message({
                message: ret.message,
                type: "success",
              });
              this.getTrainPlanList();
              this.active.SelectTrainPlan = -1;
              this.index = undefined;
              this.annualTrainId = "";
            }
          })
          .catch((err) => {
            return;
          });
      } else {
        if (this.index == undefined) {
          this.$message({
            message: "请选择一行进行删除",
            type: "warning",
          });
        } else {
          this.trainPlanList.splice(this.index, 1);
        }
      }
    },
    handlDownloadTrainPlan() {
      let parmar = {
        year: new Date(this.value3.getTime()).Format("yy"),
        enterpriseId: this.$route.query.enterpriseId,
        docType: this.dictCode,
      };
      this.$post("/biz/document/annual-train-plan/downloadTrainPlan", parmar)
        .then((ret) => {
          if (ret.status == "0") {
            window.open(ret.data.downloadFileUrl);
          } else {
            this.$message.warning({ message: res.message, duration: 1500 });
          }
        })
        .catch((err) => {
          return;
        });
    },
    trainPlanclose() {
      this.ruleForm = {};
      this.dialogTrainPlan2 = false;
      this.active.SelectTrainPlan = -1;
    },
    /* 三岗人员管理台账210 */
    getThreePostList() {
      let parmar = {
        enterpriseId: this.$route.query.enterpriseId,
        year: new Date(this.value3.getTime()).Format("yy"),
        docType: this.dictCode,
      };
      this.$post("/biz/document/three-post/queryList", parmar)
        .then((ret) => {
          this.threePostList = ret.data.postList || [];
          this.Information = ret.data || {};
          this.threePostList.map((el) => {
            el.classStyle = false;
          });
        })
        .catch((err) => {
          return;
        });
    },
    dbClickthreePost(index, item) {
      this.dialogThreePost2 = true;
      this.title2 = "新增";
      if (item.threePostId) {
        this.title2 = "修改";
        this.ruleForm1 = {
          ...item,
        };
      }
    },
    doSaveThreePost() {
      delete this.ruleForm1.createTime;
      let parmar = {
        ...this.ruleForm1,
        year: new Date(this.value3.getTime()).Format("yy"),
        enterpriseId: this.$route.query.enterpriseId,
        docType: this.dictCode,
      };
      this.$post(
        this.ruleForm1.threePostId
          ? "/biz/document/three-post/modify"
          : "/biz/document/three-post/insert",
        parmar
      ).then((ret) => {
        if (ret.status == 0) {
          this.$message({
            message: ret.message,
            type: "success",
          });
          this.dialogThreePost2 = false;
          this.ruleForm1 = {};
          this.getThreePostList();
          this.index = undefined;
          this.active.SelectThreePost = -1;
        }
      });
    },
    removeClickThreePost(index, threePostId) {
      this.threePostId = threePostId;
      this.index = index;
      this.active.SelectThreePost = index;
    },
    handlDeleteThreePost() {
      if (this.threePostId) {
        this.$post("/biz/document/three-post/delete", {
          threePostId: this.threePostId,
        })
          .then((ret) => {
            if (ret.status == 0) {
              this.$message({
                message: ret.message,
                type: "success",
              });
              this.ruleForm1 = {};
              this.getThreePostList();
              this.active.SelectThreePost = -1;
              this.index = undefined;
              this.threePostId = "";
            }
          })
          .catch((err) => {
            return;
          });
      } else {
        if (this.index == undefined) {
          this.$message({
            message: "请选择一行进行删除",
            type: "warning",
          });
        } else {
          this.threePostList.splice(this.index, 1);
        }
      }
    },
    ThreePostclose() {
      this.ruleForm1 = {};
      this.dialogThreePost2 = false;
      this.active.SelectThreePost = -1;
    },
    handlDownloadThreePost() {
      let parmar = {
        year: new Date(this.value3.getTime()).Format("yy"),
        enterpriseId: this.$route.query.enterpriseId,
        docType: this.dictCode,
      };
      this.$post("/biz/document/three-post/downloadThreePost", parmar)
        .then((ret) => {
          if (ret.status == "0") {
            window.open(ret.data.downloadFileUrl);
          } else {
            this.$message.warning({ message: res.message, duration: 1500 });
          }
        })
        .catch((err) => {
          return;
        });
    },
    /* 安全培训教育管理台账220 */
    getTeacherAccountList() {
      let parmar = {
        enterpriseId: this.$route.query.enterpriseId,
        year: new Date(this.value3.getTime()).Format("yy"),
        docType: this.dictCode,
      };
      this.$post("/biz/document/teacher-account/queryList", parmar)
        .then((ret) => {
          this.teacherAccountList = ret.data.teacherAccountList || [];
          this.Information = ret.data || {};
          this.teacherAccountList.map((el) => {
            el.classStyle = false;
          });
        })
        .catch((err) => {
          return;
        });
    },
    removeClickteacherAccount(index, teacherId, allowDelete) {
      this.teacherId = teacherId;
      this.allowDelete = allowDelete;
      this.index = index;
      this.active.SelectTeacherAccount = index;
    },
    handlDeleteTeacherAccount() {
      if (this.teacherId && this.allowDelete) {
        this.$post("/biz/document/teacher-account/delete", {
          teacherId: this.teacherId,
        })
          .then((ret) => {
            if (ret.status == 0) {
              this.$message({
                message: ret.message,
                type: "success",
              });
              this.ruleForm3 = {};
              this.getTeacherAccountList();
              this.active.SelectTeacherAccount = -1;
              this.index = undefined;
              this.teacherId = "";
            }
          })
          .catch((err) => {
            return;
          });
      } else if (this.teacherId && !this.allowDelete) {
        this.$message({
          message: "班级关联的讲师，不可删除",
        });
      } else if (this.index == undefined) {
        this.$message({
          message: "请选择一行进行删除",
          type: "warning",
        });
      } else {
        this.teacherAccountList.splice(this.index, 1);
      }
    },
    dbClickteacherAccount(index, item) {
      this.dialogTeacherAccount2 = true;
      this.title2 = "新增";
      this.ruleForm3 = {
        ...item,
      };
      if (item.teacherId) {
        this.title2 = "修改";
        this.ruleForm3 = {
          ...item,
        };
      }
    },
    doSaveTeacherAccount() {
      delete this.ruleForm3.createTime;
      let parmar = {
        ...this.ruleForm3,
        year: new Date(this.value3.getTime()).Format("yy"),
        enterpriseId: this.$route.query.enterpriseId,
        docType: this.dictCode,
      };
      this.$post(
        this.ruleForm3.teacherId
          ? "/biz/document/teacher-account/modify"
          : "/biz/document/teacher-account/insert",
        parmar
      ).then((ret) => {
        if (ret.status == 0) {
          this.$message({
            message: ret.message,
            type: "success",
          });
          this.dialogTeacherAccount2 = false;
          this.ruleForm3 = {};
          this.getTeacherAccountList();
          this.active.SelectTeacherAccount = -1;
          this.index = undefined;
        }
      });
    },
    handlDownloadTeacherAccount() {
      let parmar = {
        year: new Date(this.value3.getTime()).Format("yy"),
        enterpriseId: this.$route.query.enterpriseId,
        docType: this.dictCode,
      };
      this.$post("/biz/document/teacher-account/downloadTeacherAccount", parmar)
        .then((ret) => {
          if (ret.status == "0") {
            window.open(ret.data.downloadFileUrl);
          } else {
            this.$message.warning({ message: res.message, duration: 1500 });
          }
        })
        .catch((err) => {
          return;
        });
    },
    handlUpdateTeacherAccount() {
      let parmar = {
        year: new Date(this.value3.getTime()).Format("yy"),
        enterpriseId: this.$route.query.enterpriseId,
        docType: this.dictCode,
      };
      this.$post("/biz/document/teacher-account/updateData", parmar)
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: "更新成功",
              type: "success",
            });
            this.getTeacherAccountList();
          } else {
            this.$message.warning({ message: res.message, duration: 1500 });
          }
        })
        .catch((err) => {
          return;
        });
    },
    TeacherAccountclose() {
      this.ruleForm3 = {};
      this.dialogTeacherAccount2 = false;
      this.active.SelectTeacherAccount = -1;
    },
    /* 安全培训教育管理台账230 */
    getTraingEducationList() {
      let parmar = {
        enterpriseId: this.$route.query.enterpriseId,
        year: new Date(this.value3.getTime()).Format("yy"),
        docType: this.dictCode,
      };
      this.$post("/biz/dm/AnnualSafetyTrainLedger/detail", parmar)
        .then((ret) => {
          this.traingEducationList = ret.data.list || [];
          this.Information = ret.data || {};
          this.TraingEducationList.map((el) => {
            el.classStyle = false;
          });
        })
        .catch((err) => {
          return;
        });
    },
    removeClickTrainingEducation(index, ledgerId) {
      this.ledgerId = ledgerId;
      this.index = index;
      this.active.SelectTrainingEducation = index;
    },
    handlDeleteTraingEducation() {
      if (this.ledgerId) {
        this.$post("/biz/dm/AnnualSafetyTrainLedger/delete", {
          ledgerId: this.ledgerId,
        })
          .then((ret) => {
            if (ret.status == 0) {
              this.$message({
                message: ret.message,
                type: "success",
              });
              this.ruleForm2 = {};
              this.getTraingEducationList();
              this.active.SelectTrainingEducation = -1;
              this.index = undefined;
              this.ledgerId = "";
            }
          })
          .catch((err) => {
            return;
          });
      } else if (this.index == undefined) {
        this.$message({
          message: "请选择一行进行删除",
          type: "warning",
        });
      } else {
        this.traingEducationList.splice(this.index, 1);
      }
    },
    doSaveTrainingEducation() {
      delete this.ruleForm2.createTime;
      delete this.ruleForm2.updateTime;
      let parmar = {
        ...this.ruleForm2,
        trainDate: this.ruleForm2.trainDate
          ? this.ruleForm2.trainDate.replaceAll("/", "-")
          : "",
        year: new Date(this.value3.getTime()).Format("yy"),
        enterpriseId: this.$route.query.enterpriseId,
        docType: this.dictCode,
      };
      this.$post(
        this.ruleForm2.ledgerId
          ? "/biz/dm/AnnualSafetyTrainLedger/modify"
          : "/biz/dm/AnnualSafetyTrainLedger/insert",
        parmar
      ).then((ret) => {
        if (ret.status == 0) {
          this.$message({
            message: ret.message,
            type: "success",
          });
          this.dialogTrainingEducation2 = false;
          this.ruleForm2 = {
            trainLocation: "安知职业培训平台",
            trainAssessWay: "试卷考试",
          };
          this.getTraingEducationList();
          this.active.SelectTrainingEducation = -1;
          this.index = undefined;
        }
      });
    },
    dbClickTrainingEducation(index, item) {
      this.dialogTrainingEducation2 = true;
      this.title2 = "新增";
      if (item.docId) {
        this.title2 = "修改";
        this.ruleForm2 = {
          ...item,
        };
      }
    },
    handlDownloadTrainingEducation() {
      let parmar = {
        year: new Date(this.value3.getTime()).Format("yy"),
        enterpriseId: this.$route.query.enterpriseId,
        docType: this.dictCode,
      };
      this.$post("/biz/dm/AnnualSafetyTrainLedger/download", parmar)
        .then((ret) => {
          if (ret.status == "0") {
            window.open(ret.data.download_url);
          } else {
            this.$message.warning({ message: res.message, duration: 1500 });
          }
        })
        .catch((err) => {
          return;
        });
    },
    TrainingEducationClose() {
      this.ruleForm2 = {
        trainLocation: "安知职业培训平台",
        trainAssessWay: "试卷考试",
      };
      this.dialogTrainingEducation2 = false;
      this.active.SelectTrainingEducation = -1;
    },
    //制表人输入框切换
    extractTabulationNameClick(stu) {
      if (stu == 1) {
        this.preparedByShow = !this.preparedByShow;
      }
      if (stu == 2) {
        this.preparedDateShow = !this.preparedDateShow;
      }
      if (stu == 3) {
        this.auditByShow = !this.auditByShow;
      }
      if (stu == 4) {
        this.auditDateShow = !this.auditDateShow;
      }
      if (stu == 5) {
        this.approveByShow = !this.approveByShow;
      }
      if (stu == 6) {
        this.approveDateShow = !this.approveDateShow;
      }
    },
    handlrefreshData() {
      let parmar = {
        enterpriseId: this.$route.query.enterpriseId,
        year: new Date(this.value3.getTime()).Format("yy"),
        docType: this.dictCode,
      };
      this.$post("/biz/dm/AnnualSafetyTrainLedger/refreshData", parmar)
        .then((ret) => {
          console.log(ret);
          this.traingEducationList = ret.data.list || [];
          this.Information = ret.data || {};
          this.TraingEducationList.map((el) => {
            el.classStyle = false;
          });
        })
        .catch((err) => {
          return;
        });
    },
    /* 年度台账导入 */
    handleExport() {
      this.dialogVisibleExport = true;
    },
    downloadExcel() {
      /**
       * 下载模板
       * @param param 参数
       */
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = "/static/年度培训资金提取和使用情况管理台账上传模板.xlsx";
      link.setAttribute(
        "download",
        "年度培训资金提取和使用情况管理台账上传模板.xlsx"
      );
      document.body.appendChild(link);
      link.click();
    },
    // 导入学员
    uploadChange(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 1) {
        this.$message.error("文件大小不能超过1M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          // console.log(result);
          this.fileKey = result.data.fileKey;
          // this.fileName = result.data.fileKey;
        })
        .catch((err) => {
          return;
        });
    },
    doExport() {
      if (this.fileKey == "") {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
      } else {
        this.$confirm("是否将此表中信息导入?", "提示", {
          confirmButtonText: "确定",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            if (this.fileKey) {
              const parmar = {
                enterpriseId: this.$route.query.enterpriseId,
                key: this.fileKey,
                year: new Date(this.value3.getTime()).Format("yy"),
              };
              this.$post(
                "/biz/document/capital-amount/importParameterExcel",
                parmar,
                5000
              )
                .then((res) => {
                  if (res.status == 0) {
                    this.projectCount = res.data.projectCount;
                    this.successCount = res.data.successCount;
                    this.errorCount = res.data.errorCount;
                    if (res.data.errorCount == 0) {
                      this.$message({
                        type: "success",
                        message: "导入成功",
                      });
                    } else {
                      this.url = res.data.url;
                    }
                  }
                })
                .catch(() => {
                  return;
                });
            } else {
              this.$message({
                message: "请选择文件",
                type: "warning",
              });
            }
          })
          .catch(() => {
            return;
          });
      }
    },
    //导出错误数据
    doExportError() {
      // console.log(this.batchId);
      if (this.fileKey == "") {
        this.$message({
          message: "还没有导入数据，请先导入文档",
          type: "warning",
        });
      } else {
        window.open(this.url);
      }
    },
    doCloseload() {
      this.dialogVisibleExport = false;
      this.fileKey = "";
      this.fileName = "";
      this.getqueryList();
      this.projectCount = 0;
      this.errorCount = 0;
      this.successCount = 0;
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">
.exportdialog {
  .upload-workers {
    height: 30px;
    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;
      .el-button {
        padding: 5px 0;
      }
    }
  }
}
.dialogBox {
  height: 600px;
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.75rem 0;
    h3 {
      padding-bottom: 0.75rem;
    }
  }
  .divBox2 {
    width: 100%;
  }
}
tr {
  height: 32px;
  td {
    max-width: 7rem;
    text-align: center;
  }
}
.tableFooter {
  display: flex;
  display: flex;
  justify-content: space-around;
  padding-top: 1rem;
  > div {
    width: 50%;
    display: flex;
    > div {
      display: flex;
      align-items: center;
      margin-right: 15px;
      width: 50%;
      span {
        cursor: pointer;
      }
    }
  }
}
.tableFooter3 {
  display: flex;
  display: flex;
  justify-content: space-around;
  padding-top: 1rem;
  > div {
    width: 30%;
    display: flex;
    > div {
      display: flex;
      align-items: center;
      margin-right: 15px;
      width: 70%;
      span {
        cursor: pointer;
      }
    }
  }
}
.popUp {
  .el-dialog__header {
    overflow: hidden;
  }
  .el-dialog__body {
    padding: 0;
  }
}
.btn {
  display: flex;
  justify-content: center;
}
.pointer {
  cursor: pointer;
}
</style>
<style lang="less" scoped>
.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}
</style>
